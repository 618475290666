import cx from "clsx";
import { navigate } from "hookrouter";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Config } from "../../config";
import AlertBox from "../../partials/AlertBox";
import { AtomicCounter } from "../../partials/atomicCounter";
import AuthorizedHeader from "../../partials/AuthorizedHeader";
import Button from "../../partials/Button";
import { CheckUser } from "../../partials/checkUser";
import { CalcPrice } from "../../partials/calcPrice";
import Field from "../../partials/Field";
import Footer from "../../partials/Footer";
import Input from "../../partials/Input";
import InvoicePaymentAuthorizationModal from "../../partials/InvoicePaymentAuthorizationModal";
import InvoicePaymentGuideModal from "../../partials/InvoicePaymentGuideModal";
import Modal from "../../partials/Modal";
import PageTitle from "../../partials/PageTitle";
import Select from "../../partials/Select";
import Text from "../../partials/Text";
import Wrap from "../../partials/Wrap";
import { Telchan } from "../../Telchan";
import styles from "./Edit.module.sass";

function PaymentEdit(props) {
  const [user, setUser] = useState({});
  const [isShowingCvvGuideModal, setShowingCvvGuideModal] = useState(false);
  const [
    isShowingInvoicePaymentGuideModal,
    setShowingInvoicePaymentGuideModal,
  ] = useState(false);
  const [
    isShowingInvoicePaymentAuthorizationModal,
    setShowingInvoicePaymentAuthorizationModal,
  ] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null); //キャリアをデフォルトにする方法を考える FIXME
  const [carrierBillingType, setCarrierBillingType] = useState("au");
  const [errorMessages, setErrorMessages] = useState({
    cardNumber: [],
    cardCVV: [],
    cardMonth: [],
    cardYear: [],
  });
  const [cardNumber, setCardNumber] = useState("");
  const [cardYear, setCardYear] = useState("");
  const [cardMonth, setCardMonth] = useState("");
  const [cardCVV, setCardCVV] = useState("");
  const [showCardError, setShowCardError] = useState(false);
  const [showCarrierError, setShowCarrierError] = useState(false);
  const [showRegisteredCard, setShowRegisteredCard] = useState(false);
  const [cardChecked, setCardChecked] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [carrierForm, setCarrierForm] = useState(null);
  const [address1, setAddress1] = useState("北海道");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [showGmoCreditError, setShowGmoCreditError] = useState(false);
  const [gmoCreditErrorMessage, setGmoCreditErrorMessage] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [divisionName, setDivisionName] = useState("");

  //Shipping画面から取り急ぎコピー
  const renderPrefectureOptions = () => {
    let options = [];
    let prefectures = [
      "北海道",
      "青森県",
      "岩手県",
      "宮城県",
      "秋田県",
      "山形県",
      "福島県",
      "茨城県",
      "栃木県",
      "群馬県",
      "埼玉県",
      "千葉県",
      "東京都",
      "神奈川県",
      "新潟県",
      "富山県",
      "石川県",
      "福井県",
      "山梨県",
      "長野県",
      "岐阜県",
      "静岡県",
      "愛知県",
      "三重県",
      "滋賀県",
      "京都府",
      "大阪府",
      "兵庫県",
      "奈良県",
      "和歌山県",
      "鳥取県",
      "島根県",
      "岡山県",
      "広島県",
      "山口県",
      "徳島県",
      "香川県",
      "愛媛県",
      "高知県",
      "福岡県",
      "佐賀県",
      "長崎県",
      "熊本県",
      "大分県",
      "宮崎県",
      "鹿児島県",
      "沖縄県",
    ];
    prefectures.map((prefecture, index) => {
      options.push(<option key={index}>{prefecture}</option>);
    });
    return options;
  };

  useEffect(() => {
    onload();
  }, []);

  useEffect(() => {
    if (carrierForm != null && carrierForm.startUrl) {
      setTimeout(() => {
        document.getElementById("carrierForm").submit();
      }, 100);
    } else if (carrierForm != null) {
      //console.log('carrierForm', carrierForm);
    }
  }, [carrierForm]);

  const onload = async () => {
    let theUser = await CheckUser(props.Auth);
    if (theUser) {
      setUser(theUser);
      await fetchCarrierPayment();
    } else {
      navigate("/", false);
    }
  };

  const fetchCarrierPayment = async () => {
    const session = await props.Auth.currentSession();
    const response = await props.API.get("Purchase", "/payments", {
      headers: { Authorization: session.idToken.jwtToken },
    });
    if (response.length > 0) {
      setCarrierBillingType(response[0].carrier);
      if (response[0].carrier == 'GMO掛け払い') {
        setPaymentMethod("GMO掛け払い");
      } else if (response[0].carrier == 'credit') {
        await fetchCardList();
      } else {
        setPaymentMethod("carrier");
      }
    }
  };

  const fetchCardList = async () => {
    try {
      const session = await props.Auth.currentSession();
      const response = await props.API.post("Purchase", "/billing", {
        headers: { Authorization: session.idToken.jwtToken },
        body: {
          action: "list-cards",
        },
      });
      if (response[0]) {
        setShowRegisteredCard(true);
        setCardNumber(response[0].cardNo);
        setCardYear(response[0].expire);
      }
      setCardChecked(true);
    } catch (e) {
      setCardChecked(true);
    }
  };

  const submit = async (e) => {
    setSubmitButtonDisabled(true);
    setErrorMessages({});
    setShowCardError(false);
    setShowCarrierError(false);
    setShowGmoCreditError(false);
    setGmoCreditErrorMessage("");
    let errors = {
      cardNumber: [],
      cardCVV: [],
      cardMonth: [],
      cardYear: [],
      address1: [],
      address2: [],
      address3: [],
      zipCode: [],
      email: [],
      name: [],
      phoneNumber: [],
    };

    if (paymentMethod === "credit") {
      if (!cardNumber) {
        errors["cardNumber"].push("カード番号を入力して下さい");
      }
      if (cardYear == null) {
        errors["cardYear"].push("有効期限（年）を入力して下さい");
      }
      if (cardMonth == null) {
        errors["cardMonth"].push("有効期限（月）を入力して下さい");
      }
      if (cardCVV == null) {
        errors["cardCVV"].push("セキュリティコードを入力して下さい");
      }
    }

    if (paymentMethod == 'GMO掛け払い') {
      if (address1 == "") {
        errors['address1'].push("都道府県を選択してください");
      }
      if (address2 == "") {
        errors['address2'].push("市区町村を入力してください");
      }
      if (address3 == "") {
        errors['address3'].push("それ以降の住所を入力してください");
      }
      if (name == "") {
        errors['name'].push("お名前を入力してください");
      }
      if (zipCode == "") {
        errors['zipCode'].push("郵便番号を入力してください");
      }
      if (phoneNumber == "") {
        errors['phoneNumber'].push("電話番号を入力してください");
      }
    }

    let hasError = false;
    for (let key in errors) {
      if (errors[key].length > 0) {
        hasError = true;
      }
    }

    if (hasError) {
      setSubmitButtonDisabled(false);
      setErrorMessages(errors);
      setShowCardError(true);
      window.scrollTo(0, 0);
      return;
    } else {
      const session = await props.Auth.currentSession();
      if (paymentMethod == "credit") {
        await card(session);
      } else if (paymentMethod == "GMO掛け払い") {
        await gmoCredit(session);
      } else {
        await carrier(session);
      }
    }
  };

  const gmoCredit = async (session) => {
    try {
      const saveData = {
        email: user.attributes.email,
        billingAddressName: name,
        billingAddress1: address1,
        billingAddress2: address2,
        billingAddress3: address3,
        billingAddressPhoneNumber: phoneNumber,
        billingAddressZipcode: zipCode,
      };
      const sessionData = await props.API.post("SecureSession", "/session", {
        headers: { Authorization: session.idToken.jwtToken },
        body: saveData,
      });
      const unique_id = sessionData.session_id;
      const result = await props.API.post("Purchase", "/billing", {
        headers: { Authorization: session.idToken.jwtToken },
        body: {
          uniqueId: unique_id,
          action: "gmo-credit-create-customer",
        },
      });
      if (result.error) {
        setSubmitButtonDisabled(false);
        setGmoCreditErrorMessage(result.message);
        setShowGmoCreditError(true);
      } else {
        navigate("/payment", false);
      }
    } catch (e) {
    }
    setSubmitButtonDisabled(false);
  };

  const carrier = async (session) => {
    try {
      const counter = await AtomicCounter.get();
      const order_id = String(counter).padStart(20, "0");
      const saveData = {
        mode: "resigtrationOnly",
        user_id: user.attributes.sub,
        action: "carrier_payment",
        carrier: carrierBillingType,
      };
      const sessionData = await props.API.post("SecureSession", "/session", {
        headers: { Authorization: session.idToken.jwtToken },
        body: saveData,
      });
      const unique_id = sessionData.session_id;
      const charge = await props.API.post("Purchase", "/billing", {
        headers: { Authorization: session.idToken.jwtToken },
        body: {
          sessionId: unique_id,
          orderId: order_id,
          carrier: carrierBillingType,
          action: "carrier_payment",
          returnUrl: `${Config.api_url_base2}/carrier_payment/${order_id}/${unique_id}`,
        },
      });
      if (!charge.startUrl) {
        setSubmitButtonDisabled(false);
        setShowCarrierError(true);
      } else {
        setCarrierForm(charge); //利用許諾にリダイレクト
      }
    } catch (e) {
      setSubmitButtonDisabled(false);
      setShowCarrierError(true);
    }
  };

  const card = async (session) => {
    try {
      window.Multipayment.init(Config.gmo_shop_id);
      const tokenParams = {
        cardno: cardNumber,
        expire: `${cardYear}${cardMonth}`,
        securitycode: cardCVV,
      };
      const tokenResponse = await window.Multipayment.getToken(
        tokenParams,
        async (res) => {
          try {
            if (res.resultCode == "000") {
              const token = res.tokenObject.token;
              const data = {
                action: "create-credit-card",
                forceOverride: true,
                token: token,
              };
              const response = await props.API.post("Purchase", "/billing", {
                headers: { Authorization: session.idToken.jwtToken },
                body: data,
              });

              while (true) {
                const listResponse = await props.API.post(
                  "Purchase",
                  "/billing",
                  {
                    headers: { Authorization: session.idToken.jwtToken },
                    body: { action: "list-cards" },
                  }
                );

                if (listResponse.length > 1) {
                  await props.API.post("Purchase", "/billing", {
                    headers: { Authorization: session.idToken.jwtToken },
                    body: { action: "delete-card", cardSeq: 0 },
                  });
                } else {
                  break;
                }
              }
              navigate("/payment", false);
            } else {
              setShowCardError(true);
              setSubmitButtonDisabled(false);
            }
          } catch (e) {
            setShowCardError(true);
            setSubmitButtonDisabled(false);
          }
        }
      );
    } catch (e) {
      setSubmitButtonDisabled(false);
      setShowCardError(true);
    }
  };

  return (
    <>
      {carrierForm && (
        <form id="carrierForm" action={carrierForm.startUrl} method="POST">
          <input type="hidden" name="AccessID" value={carrierForm.accessId} />
          <input type="hidden" name="Token" value={carrierForm.token} />
        </form>
      )}
      <AuthorizedHeader title="test" type="Close" backURL="/payment" />
      <Wrap>
        <PageTitle title="お支払い方法の登録" />

        {cardChecked && showCardError && (
          <AlertBox
            gutterBottom
            text="クレジットカード情報が正しくありません。カード番号と有効期限をご確認のうえ、入力しなおしてください。"
          />
        )}
        {showCarrierError && (
          <AlertBox
            gutterBottom
            text="キャリア決済の設定中にエラーが発生しました。ご利用のキャリアをご確認の上、再度お試しください。"
          />
        )}
        {showGmoCreditError && (
          <AlertBox
            gutterBottom
            text={gmoCreditErrorMessage}
          />
        )}

        <div
          onClick={() => setPaymentMethod("credit")}
          className={cx(
            styles.paymentMethod,
            paymentMethod === "credit" ? styles.active : null
          )}
        >
          <div className={styles.paymentSelectArea}>
            <div className={styles.paymentSelectAreaLeft}>
              <div className={styles.paymentSelectButton}></div>
            </div>
            <div className={styles.paymentSelectAreaRight}>
              <div className={styles.paymentMethodTitle}>
                クレジットカード決済
              </div>
            </div>
          </div>
          {paymentMethod === "credit" && (
            <div className={styles.paymentDetailArea}>
              <Field label="カード番号">
                <Input
                  value={cardNumber}
                  onChange={(e) => {
                    setCardNumber(e.target.value);
                  }}
                  type="number"
                  placeholder="半角数字のみ"
                  errormessage={errorMessages["cardNumber"]}
                />
              </Field>
              <div className={styles.creditCardCompanyArea}>
                <div className={styles.creditCardCompany}>
                  <img
                    src="/assets/images/icon-creditcard-visa.png"
                    height="22"
                    alt="VISA"
                  />
                </div>
                <div className={styles.creditCardCompany}>
                  <img
                    src="/assets/images/icon-creditcard-master.png"
                    height="22"
                    alt="Master"
                  />
                </div>
                <div className={styles.creditCardCompany}>
                  <img
                    src="/assets/images/icon-creditcard-jcb.png"
                    height="22"
                    alt="JCB"
                  />
                </div>
                <div className={styles.creditCardCompany}>
                  <img
                    src="/assets/images/icon-creditcard-american-express.png"
                    height="22"
                    alt="AmericanExpress"
                  />
                </div>
                <div className={styles.creditCardCompany}>
                  <img
                    src="/assets/images/icon-creditcard-diners-club.png"
                    height="22"
                    alt="DinersClub"
                  />
                </div>
              </div>
              <Field label="有効期限">
                <div className={styles.creditCardFieldWrap}>
                  <div className={styles.creditCardField}>
                    <Select
                      onChange={(e) => {
                        setCardMonth(e.target.value);
                      }}
                      value={cardMonth}
                      errormessage={errorMessages["cardMonth"]}
                    >
                      <option>----</option>
                      {[...Array(12).keys()].map((n) => {
                        const v = n + 1 < 10 ? `0${n + 1}` : n + 1;
                        return (
                          <option key={`m-${v}`} value={v}>
                            {v}
                          </option>
                        );
                      })}
                    </Select>
                    <div className={styles.creditCardUnit}>月</div>
                  </div>
                  <div className={styles.creditCardField}>
                    <Select
                      onChange={(e) => {
                        setCardYear(e.target.value);
                      }}
                      value={cardYear}
                      errormessage={errorMessages["cardYear"]}
                    >
                      <option>----</option>
                      {[...Array(10).keys()].map((n) => {
                        const year = moment().add(n, "years").format("YYYY");
                        return (
                          <option value={year} key={`y-${year}`}>
                            {year}
                          </option>
                        );
                      })}
                    </Select>
                    <div className={styles.creditCardUnit}>年</div>
                  </div>
                </div>
              </Field>
              <Field label="セキュリティーコード（CVV）">
                <Input
                  value={cardCVV}
                  onChange={(e) => {
                    setCardCVV(e.target.value);
                  }}
                  type="number"
                  placeholder="3桁もしくは4桁の番号"
                  errormessage={errorMessages["cardCVV"]}
                />
              </Field>
              <span
                className={styles.creditCvcArea}
                onClick={() => setShowingCvvGuideModal(true)}
              >
                <img src="/assets/images/icon-cvc.png" width="17" />
                <div className={styles.cvcText}>セキュリティーコードとは？</div>
              </span>
              <div className={styles.creditSaveText}>
                お客様のクレジットカード情報はGMOペイメントゲートウェイ株式会社のシステムで管理します。
              </div>
            </div>
          )}
        </div>
        <div
          onClick={() => setPaymentMethod("carrier")}
          className={cx(
            styles.paymentMethod,
            paymentMethod === "carrier" ? styles.active : null
          )}
        >
          <div className={styles.paymentSelectArea}>
            <div className={styles.paymentSelectAreaLeft}>
              <div className={styles.paymentSelectButton}></div>
            </div>
            <div className={styles.paymentSelectAreaRight}>
              <div className={styles.paymentMethodTitle}>キャリア決済</div>
            </div>
          </div>
          {paymentMethod === "carrier" && (
            <div className={styles.paymentDetailArea}>
              <div className={styles.carrierBillingText}>
                キャリアを選択してください
              </div>
              <Select
                value={carrierBillingType}
                onChange={(e) => setCarrierBillingType(e.target.value)}
              >
                <option>----</option>
                <option value="au">au</option>
                <option value="docomo">docomo</option>
                {/*<option value="softbank">SoftBank</option>*/}
              </Select>
            </div>
          )}
        </div>
        <>
          {/* GMO掛け払いを選択中のみ詳しい情報を表示する */}
          {paymentMethod === "GMO掛け払い" && (
            <>
              <div
                onClick={() => setPaymentMethod("GMO掛け払い")}
                className={cx(
                  styles.paymentMethod,
                  paymentMethod === "GMO掛け払い" ? styles.active : null
                )}
              >
                <div>
                  <div className={styles.paymentSelectArea}>
                    <div className={styles.paymentSelectAreaLeft}>
                      <div className={styles.paymentSelectButton}></div>
                    </div>
                    <div className={styles.paymentSelectAreaRight}>
                      <div className={styles.paymentMethodTitle}>
                        <div>請求書払い</div>
                        <div className={styles.sub}>
                          銀行振込/コンビニ/スマホ決済
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={styles.paymentDetailArea}>
                    <Text>
                      今月の利用料を翌月に請求書でお支払いできます。翌月に発送します。
                      <br />
                      請求書の発行費用として¥{CalcPrice.gmoChargeWithTax}かかります。
                      <br />
                      お支払い期限日までにお支払いください。
                      <div
                        className={styles.link}
                        onClick={() => setShowingInvoicePaymentGuideModal(true)}
                      >
                        ご利用可能なお振込先の一覧
                      </div>
                    </Text>
                    <Text variant="ex">
                      お客様の個人情報はGMOペイメントサービス株式会社のシステムで管理します。
                    </Text>
                  </div>
                  <Text variant="h3" gutterBottom>請求書の送り先</Text>
                  <div className={styles.billingAddress}>
                    <Field label="お名前 ※スペース含む20文字まで" required>
                      <Input
                        type="text"
                        maxLength="20"
                        placeholder="例）山田てる子"
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        value={name}
                        errorMessages={errorMessages.name}
                      />
                    </Field>
                    <Field label="組織名">
                      <Input
                        type="text"
                        maxLength={50}
                        placeholder="例）〇〇組合・〇〇株式会社"
                        onChange={(e) => {
                          setOrganizationName(e.target.value);
                        }}
                        value={organizationName}
                        errorMessages={errorMessages.organizationName}
                      />
                    </Field>
                    <Field label="部署名">
                      <Input
                        maxLength={50}
                        type="text"
                        placeholder="例）〇〇課"
                        onChange={(e) => {
                          setDivisionName(e.target.value);
                        }}
                        value={divisionName}
                        errorMessages={errorMessages.divisionName}
                      />
                    </Field>
                    <Field label="郵便番号" required>
                      <Input
                        type="tel"
                        maxLength="10"
                        placeholder="例）1234567"
                        onChange={(e) => {
                          setZipCode(e.target.value.replace(/[^\d]/g, ''));
                        }}
                        value={zipCode}
                        errorMessages={errorMessages.zipCode}
                      />
                    </Field>
                    <Field label="都道府県" required>
                      <Select
                        onChange={(e) => {
                          setAddress1(e.target.value);
                        }}
                        value={address1}
                      >
                        {renderPrefectureOptions()}
                      </Select>
                    </Field>
                    <Field label="市区町村" required>
                      <Input
                        type="text"
                        placeholder="例）港区"
                        onChange={(e) => {
                          setAddress2(e.target.value);
                        }}
                        value={address2}
                        errorMessages={errorMessages.address2}
                      />
                    </Field>
                    <Field label="それ以降の住所" required>
                      <Input
                        type="text"
                        placeholder="例）南青山2-26-1 南青山ビル105"
                        onChange={(e) => {
                          setAddress3(e.target.value);
                        }}
                        value={address3}
                        errorMessages={errorMessages.address3}
                      />
                    </Field>
                    <Field label="電話番号" required>
                      <Input
                        type="tel"
                        placeholder="例）09012345678"
                        onChange={(e) => {
                          setPhoneNumber(e.target.value);
                        }}
                        value={phoneNumber}
                        errorMessages={errorMessages.phoneNumber}
                      />
                    </Field>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
        {paymentMethod !== 'GMO掛け払い' &&
          <div className={styles.invoicePaymentDescription}>
            <Text variant="note">
              上記のお支払い方法が難しい場合、請求書払いを選択することもできます。※別途手数料が発生いたします。
              <div
                className={styles.link}
                onClick={() => setPaymentMethod("GMO掛け払い")}
              >
                請求書払いを選択する
              </div>
            </Text>
          </div>
        }
        <div className={styles.action}>
          <Button disabled={submitButtonDisabled} onClick={submit} primary fit>
            保存する
          </Button>
        </div>
        <div className={styles.backPageButton}>
          <span
            onClick={() => {
              navigate("/payment");
            }}
            className={styles.backPageButtonColor}
          >
            キャンセル
          </span>
        </div>
      </Wrap>
      <Footer />

      {/* セキュリティコードの解説モーダル */}
      <Modal
        show={isShowingCvvGuideModal}
        type="Dialog"
        title="セキュリティコード（CVV）
      "
      >
        <div className={styles.securityCodeText}>
          カードの裏面もしくは前面にある
          <br />
          3桁または4桁の番号です。
        </div>
        <div className={styles.securityCodeImage}>
          <img
            src="/assets/images/icon-creditcard-back.png"
            width="88"
            className={styles.creditBackside}
          />
          <img src="/assets/images/icon-creditcard-front.png" width="88" />
        </div>
        <div>
          <Button fit onClick={() => setShowingCvvGuideModal(false)}>
            閉じる
          </Button>
        </div>
      </Modal>

      {/* 請求書払いの解説モーダル */}
      <InvoicePaymentGuideModal
        open={isShowingInvoicePaymentGuideModal}
        onClose={() => setShowingInvoicePaymentGuideModal(false)}
      />

      {/* 請求書払い審査中モーダル */}
      {/* TODO: GMO掛け払い（変更実行後にモーダルを表示してください。審査に失敗した場合、statusをNGに切り替えてください） */}
      <InvoicePaymentAuthorizationModal
        open={isShowingInvoicePaymentAuthorizationModal}
        status="Progress"
        onClose={() => setShowingInvoicePaymentAuthorizationModal(false)}
      />
    </>
  );
}

export default PaymentEdit;
